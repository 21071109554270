@import url("https://fonts.googleapis.com/css2?family=Dela+Gothic+One&family=Oswald:wght@200;300;600;700&family=Poppins:wght@100&family=Roboto:wght@300;400&display=swap");

:root {
  --main-bg: #e1e1e1;
  --secondary-bg: black;
  --gray-bg: #151515;
  --main-text-color: #000000;
  --sub-text-color: white;
  --border-color: rgba(255, 255, 255, 0.162);
  --title-text: 5rem;
  --sub-heading-text: 2.2rem;
  --sub-text: 1.1rem;
  scroll-behavior: smooth;
}
.hiddenRight {
  opacity: 0;
  transform: translateX(100px);
}
.hiddenLeft {
  opacity: 0;
  transform: translateX(-100px);
}
.hiddenDown {
  opacity: 0;
  transform: translateY(100px);
}
.show {
  opacity: 1;
  transform: translateX(0);
}
.grid {
  height: 16rem;
  background-image: linear-gradient(
      to right,
      rgb(203 213 225) 1px,
      transparent 1px
    ),
    linear-gradient(to bottom, rgb(203 213 225) 1px, transparent 1px);
  background-size: 3.5rem 3.5rem;
  background-position: center center;
  z-index: -99;
}
.hero-wrapper {
  min-height: 100vh;
  background-color: var(--main-bg);
  color: var(--main-text-color);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  font-family: oswald;
  overflow: hidden;
}
.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.intro-text,
.outro-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* text-transform: uppercase; */
  font-size: 30px;
  cursor: pointer;
  font-family: monospace;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.outro-text {
  margin-top: 2vh;
}
.online-banner {
  /* left: 50%; */
  font-family: monospace;
  z-index: 999;
  /* background-color: rgba(0, 0, 0, 0.342); */
  border: 2px solid rgba(0, 0, 0, 0.342);
  padding: 5px 10px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.online-icon {
  height: 10px;
  width: 10px;
  background-color: green;
  margin-left: 10px;
  border-radius: 50%;
}
.hero-text {
  display: flex;
  text-transform: uppercase;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-family: Arges heavy; */
  font-weight: 800;
}
.ht1,
.ht2 {
  cursor: pointer;
  font-weight: 700;
  font-size: 9rem;
  transform: scale(1, 1.3);
}
.ht1 {
  margin-bottom: -30px;
}
.about {
  height: 100vh;
  width: 100%;
  padding: 0 10%;
  background-color: var(--secondary-bg);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--sub-text-color);
}
.about-img {
  width: 45%;
}
.myimg {
  width: 100%;
  transition: all 1s;
  cursor: pointer;
}
.about-content {
  width: 40%;
  display: flex;
  flex-direction: column;
  margin-left: 5%;
  /* background-color: red; */
}
.project-section-title,
.about-title {
  font-size: var(--title-text);
  font-family: oswald;
  font-weight: 600;
  text-transform: uppercase;
  transition: all 1s;
}
.about-title {
  text-align: left;
  margin-bottom: 20px;
  overflow-x: hidden;
}
.project-section-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 2%; */
}
.scroll-down {
  font-family: monospace;
  font-size: 16px;
  margin-right: 5%;
  opacity: 30%;
  display: flex;
  align-items: center;
}
.scroll-down-icon {
  margin-left: 10px;
}
.about-text {
  width: 80%;
  font-family: monospace;
  font-size: var(--sub-text);
  transition: all 1s;
}

.projects-wrapper {
  /* scroll-snap-type: x; */
  height: 300vh;
  position: relative;
}
.projects {
  background-color: var(--secondary-bg);
  height: 100vh;
  padding: 0 0 1% 5%;
  color: var(--sub-text-color);
  font-family: oswald;
  position: sticky;
  top: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.project-group {
  /* min-width: 80vw; */
  display: flex;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.119);
  padding: 20px;
  border-radius: 10px;
  margin-right: 100px;
  transition: all 1s;
  gap: 30px;
  font-family: monospace;
}
.award-banner {
  background-color: #eb4542;
  position: absolute;
  right: -10px;
  top: 30px;
  padding: 3px;
  padding-left: 40px;
  color: var(--main-text-color);
  /* text-transform: uppercase; */
  font-size: 18px;
  width: 20%;
  z-index: 99;
  clip-path: polygon(100% 0, 100% 100%, 0% 100%, 10% 50%, 0% 0%);
  border-top-right-radius: 10px;
  font-family: oswald;
}
.award-banner-after {
  position: absolute;
  content: "";
  background-color: #7e2624;
  height: 20px;
  width: 10px;
  right: -10px;
  top: 55px;
  border-bottom-right-radius: 10px;
}
.project-name {
  font-size: var(--sub-heading-text);
  cursor: pointer;
  font-family: oswald;
}
.project-info-card {
  width: 45%;
}
.project-info {
  margin-top: 2rem;
  font-size: 16px;
  /* font-size: var(--sub-text); */
}
.tech-stack {
  margin-top: 20px;
  font-size: 14px;
}
.tech-language {
  display: inline-block;
  background-color: var(--gray-bg);
  padding: 5px 8px;
  margin-right: 5px;
  border-radius: 3px;
  color: grey;
  transition: 0.3s all;
  cursor: pointer;
}
.tech-language:hover {
  background-color: black;
  color: white;
  transform: scale(1.02);
}
.cards {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(6, 1fr);
  /* gap: 100px; */
  margin-top: 20px;
}

.card {
  background-color: var(--gray-bg);
  /* margin-right: 100px; */
  width: 48vw;
  height: 60vh;
  border-radius: 20px 20px 10px 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid var(--border-color);
  transition: 0.3s all;
}
.card:hover .card-inner img {
  transform: scale(1.03);
}
.card-outer {
  height: 7%;
  border-radius: inherit;
  padding: 0 0 0 5%;
  display: flex;
  align-items: center;
}
.circle {
  height: 12px;
  width: 12px;
  /* background-color: rgba(255, 255, 255, 0.304); */
  background-color: rgb(63, 63, 63);
  border-radius: 50%;
  margin-right: 5px;
  /* border: 1px solid var(--border-color); */
}
.circle:nth-child(1) {
  background-color: #ff5f5cbc;
}
.circle:nth-child(2) {
  background-color: #ffbd44bc;
}
.circle:nth-child(3) {
  background-color: #00ca4ebc;
}
.card-inner {
  /* background-color: blue; */
  height: 93%;
  display: flex;
  /* text-align: center; */
  /* justify-content: center; */
  overflow: hidden;
}
.card-inner img {
  border-radius: 20px 20px 0 0;
  padding: 0 5px;
  width: 100%;
  object-fit: cover;
  position: center;
  transition: 0.3s all;
}
.project-list-section {
  background-color: var(--secondary-bg);
  /* min-height: 100vh; */
  padding: 5% 5% 1% 5%;
  color: var(--sub-text-color);
  font-family: oswald;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.project-item {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  margin-bottom: 10vh;
}
.section-project-info-card {
  /* background-color: red; */
  /* width: 80%;  */
  width: 40%;
  margin-right: 10%;
}
.section-project-name {
  font-size: 4rem;
  font-weight: 600;
}
.section-card {
  width: 35vw;
  height: 50vh;
  margin: auto;
}
.kakshya-title {
  font-weight: 600;
  color: #cca061;
}
.ocr-title,
.easycanteen-title {
  font-weight: 600;
}
.ocr-title-green {
  color: #42b065;
}
.easycanteen-title-green {
  color: #2bcc3e;
}

@media screen and (max-width: 1100px) {
  .ht1,
  .ht2 {
    font-size: 7rem;
  }
  .intro-text,
  .outro-text {
    font-size: 25px;
  }
  .about {
    flex-direction: column;
  }
  .about-content {
    width: 70%;
    margin: 0;
  }
  .about-img {
    width: 70%;
    margin-bottom: 40px;
  }
  .about-text {
    width: 100%;
  }
  .award-banner {
    width: 25%;
  }
  .hiddenDown {
    opacity: 1;
    transform: translateX(0px);
  }
  .scroll-down {
    display: none;
  }
  .projects {
    position: unset;
    height: auto;
    padding: 0%;
  }
  .project-group {
    /* background-color: greenyellow; */
    margin: 0;
    margin-bottom: 40px;
    flex-direction: column;
  }
  .project-info-card {
    width: 100%;
    margin: 0;
  }
  .cards {
    display: block;
    /* overflow: hidden; */
    margin: 50px;
    /* padding-bottom: 10px; */
  }
  .card {
    /* background-color: blue; */
    width: 100%;
    height: auto;
  }
  .card-outer {
    margin: 8px;
  }
  .project-section-title {
    padding: 20px;
    /* font-size: 4rem; */
    text-align: center;
    justify-content: center;
  }
  .projects-wrapper {
    height: auto;
  }
}
@media screen and (max-width: 900px) {
  .hiddenDown {
    opacity: 1;
    transform: translateX(0px);
  }
  .ht1,
  .ht2 {
    font-size: 5rem;
  }
  .intro-text,
  .outro-text {
    font-size: 20px;
  }
  .ht1 {
    margin-bottom: -10px;
  }
  .about {
    overflow: hidden;
  }
  .about-content {
    align-items: center;
  }
  .about-title {
    font-size: 4rem;
    margin-bottom: 10px;
  }
  .project-section-title {
    font-size: 4rem;
  }
  .circle {
    height: 10px;
    width: 10px;
  }
  .award-banner {
    width: 35%;
  }
  .cards {
    margin: 30px;
  }
}
@media screen and (max-width: 600px) {
  .grid {
    background-size: 2.5rem 2.5rem;
  }
  .ht1,
  .ht2 {
    font-size: 4rem;
  }
  .intro-text,
  .outro-text {
    font-size: 18px;
  }
  .ht1 {
    margin-bottom: -10px;
  }
  .projects-wrapper {
    overflow: hidden;
  }
  .about-img {
    width: 80%;
  }
  .about-title {
    font-size: 3rem;
    margin-bottom: 10px;
  }
  .project-section-title {
    font-size: 3rem;
  }
  .about-content {
    width: 80%;
    margin-left: 0;
  }
  .project-name {
    font-size: 25px;
  }
  .project-info {
    margin-top: 10px;
  }
  .about-text,
  .project-info {
    font-size: 16px;
  }
  /* .projects-wrapper{
    height: auto;
  }
  .cards{

  } */
  .circle {
    height: 8px;
    width: 8px;
  }
  .award-banner {
    font-size: 14px;
    width: 40%;
  }
  .award-banner-after {
    top: 50px;
  }
}
@media screen and (max-width: 500px) {
  .grid {
    background-image: linear-gradient(
        to right,
        rgba(203, 213, 225, 0.85) 1px,
        transparent 1px
      ),
      linear-gradient(to bottom, rgb(203, 213, 225, 0.85) 1px, transparent 1px);
  }
  .hero-wrapper,
  .about {
    min-height: 90vh;
  }
  .hero-text {
    align-items: baseline;
  }
  .ht1,
  .ht2 {
    font-size: 3rem;
  }

  .intro-text,
  .outro-text {
    text-align: left;
    /* align-items: baseline; */
    font-size: 14px;
  }
  .intro-text {
    display: block;
    text-align: left;
    width: 80%;
  }
  .outro-text {
    padding: 0 10%;
  }
  .ht1 {
    margin-bottom: -5px;
  }
  .award-banner {
    font-size: 14px;
    width: 50%;
  }
  .about-text,
  .project-info {
    font-size: 14px;
  }
}
@media screen and (max-width: 360px) {
  .hero-text {
    align-items: center;
  }
  .intro-text,
  .outro-text {
    text-align: center;
  }
}
