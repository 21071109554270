* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body::-webkit-scrollbar {
  width: 0.7em;
}

body::-webkit-scrollbar-track {
  background-color: black;
}

body::-webkit-scrollbar-thumb {
  background-color: white;
}
.cursor {
  position: fixed;
  z-index: 999999;
  background-color: white;
  font-size: 20px;
  color: white;
  /* font-weight: 700; */
  font-family: oswald;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  mix-blend-mode: difference;
  /* filter: invert(1)  grayscale(1) contrast(2); */
  pointer-events: none;
}
.link-icon {
  font-size: 25px;
}
@media screen and (max-width: 1100px) {
  .cursor {
    display: none;
  }
}
